import styled, { css } from 'styled-components'
import { AppBar } from '@mui/material'

export const AdminHeaderStyled = styled(AppBar)(
  ({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.brown};

    a {
      text-decoration: none;
      color: inherit;
    }

    a.inactive:hover {
      text-decoration: underline dotted;
    }

    a.active {
      font-weight: bold;
    }

    div.menu {
      a {
        text-align: center;
        font-size: ${theme.global.typography.size['size-18']};
      }

      .title {
        color: #ddd;
        text-align: center;
        font-weight: bold;
        width: 180px;
        margin-right: ${theme.spacing(2)};
      }

      .log-out-btn {
        padding: ${theme.spacing(0.25, 1.25)};
      }
    }
  `
)
